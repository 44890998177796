import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import classNames from "classnames";
import {
  changePrice,
  changeMaterial,
  changeThickness,
  changeColour,
  changeWidth,
  changeLength,
  changeQuantity,
  changeMinMaxLength,
  changeMinMaxWidth,
  changeColourOptions,
  changeThicknessOptions,
  changeCallToOrder,
  changeRowCallToOrder,
  deleteRow,
  addRow,
} from "./formRowsSlice";
import { Button } from "./Button";
import { PriceIcon } from "./icons/PriceIcon";
import { Validation } from "calidation";

import styles from "./FormRow.module.css";
import parentStyles from "./FormRows.module.css";

const DeleteIcon = ({ fill }) => (
  <svg width="20px" height="22px" viewBox="0 0 20 22">
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="5"
        transform="translate(-1115.000000, -569.000000)"
        fill={fill}
        fill-rule="nonzero"
      >
        <g
          id="delete-trash-dust-bin-remove-recycle-bin"
          transform="translate(1115.000000, 569.000000)"
        >
          <path
            d="M19,3.4 L13.1,3.4 L13.1,2 C13.1,1.118 12.382,0.4 11.5,0.4 L8.5,0.4 C7.618,0.4 6.9,1.118 6.9,2 L6.9,3.4 L1,3.4 C0.668,3.4 0.4,3.668 0.4,4 C0.4,4.332 0.668,4.6 1,4.6 L2.4,4.6 L2.4,18 C2.4,19.985 4.015,21.6 6,21.6 L14,21.6 C15.985,21.6 17.6,19.985 17.6,18 L17.6,4.6 L19,4.6 C19.332,4.6 19.6,4.331 19.6,4 C19.6,3.669 19.332,3.4 19,3.4 Z M8.1,2 C8.1,1.779 8.279,1.6 8.5,1.6 L11.5,1.6 C11.721,1.6 11.9,1.779 11.9,2 L11.9,3.4 L8.1,3.4 L8.1,2 Z M16.4,18 C16.4,19.323 15.323,20.4 14,20.4 L6,20.4 C4.677,20.4 3.6,19.323 3.6,18 L3.6,4.6 L16.4,4.6 L16.4,18 Z M6.9,17 L6.9,8 C6.9,7.668 7.169,7.4 7.5,7.4 C7.831,7.4 8.1,7.669 8.1,8 L8.1,17 C8.1,17.332 7.831,17.6 7.5,17.6 C7.169,17.6 6.9,17.332 6.9,17 Z M11.9,17 L11.9,8 C11.9,7.668 12.169,7.4 12.5,7.4 C12.831,7.4 13.1,7.669 13.1,8 L13.1,17 C13.1,17.332 12.831,17.6 12.5,17.6 C12.169,17.6 11.9,17.332 11.9,17 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export const FormRow = (props) => {
  const dispatch = useDispatch();

  const price = useSelector((state) =>
    Number(state.formRows.rows[props.rowIndex].price)
  );
  const material = useSelector(
    (state) => state.formRows.rows[props.rowIndex].material
  );
  const thickness = useSelector(
    (state) => state.formRows.rows[props.rowIndex].thickness
  );
  const width = useSelector(
    (state) => state.formRows.rows[props.rowIndex].width
  );
  const length = useSelector(
    (state) => state.formRows.rows[props.rowIndex].length
  );
  const quantity = useSelector(
    (state) => state.formRows.rows[props.rowIndex].quantity
  );
  const color = useSelector(
    (state) => state.formRows.rows[props.rowIndex].colour
  );
  const unitPrice = useSelector(
    (state) => state.formRows.rows[props.rowIndex].unitPrice
  );
  const minWidth = useSelector(
    (state) => state.formRows.rows[props.rowIndex].minWidth
  );
  const maxWidth = useSelector(
    (state) => state.formRows.rows[props.rowIndex].maxWidth
  );
  const minLength = useSelector(
    (state) => state.formRows.rows[props.rowIndex].minLength
  );
  const maxLength = useSelector(
    (state) => state.formRows.rows[props.rowIndex].maxLength
  );
  const colourOptions = useSelector(
    (state) => state.formRows.rows[props.rowIndex].colourOptions
  );
  const thicknessOptions = useSelector(
    (state) => state.formRows.rows[props.rowIndex].thicknessOptions
  );

  const thicknessSelect = useRef(null);
  const colourSelect = useRef(null);

  let btnStyle = {
    opacity: props.rowIndex !== 0 ? 1 : 0,
  };

  useEffect(() => {
    let filteredConfig =
      props.options.filter((mat) => mat.material === material).length > 0
        ? props.options.filter((mat) => mat.material === material)[0]
            .thicknesses
        : null;
    if (filteredConfig) {
      let allowedThicknesses = filteredConfig.filter((itm) => {
        return itm.colours.filter((clrItm) => {
          return clrItm.colour === color;
        }).length > 0
          ? true
          : false;
      });

      dispatch(
        changeThicknessOptions({
          row: props.rowIndex,
          thicknesses: allowedThicknesses,
        })
      );
      dispatch(changeThickness({ row: props.rowIndex, thickness: "none" }));
    }

    if (colourSelect && colourSelect.current) {
      colourSelect.current.dispatchEvent(
        new Event("change", { bubbles: true })
      );
    }
  }, [color]);

  // Update colour and min/max width when thickness changed
  useEffect(() => {
    let filteredConfig =
      props.options.filter((mat) => mat.material === material).length > 0
        ? props.options.filter((mat) => mat.material === material)[0]
            .thicknesses
        : null;

    if (filteredConfig) {
      let filteredThickness = filteredConfig.filter(
        (thick) => thick.thickness === thickness
      )[0];

      if (filteredThickness) {
        let colour = filteredThickness.colours.filter(
          (itm) => itm.colour === color
        )[0];
        if (colour) {
          let image = colour.image;

          dispatch(
            changeColour({
              row: props.rowIndex,
              colour: colour.colour,
              image: image,
            })
          );

          dispatch(
            changeMinMaxWidth({
              row: props.rowIndex,
              min: Number(filteredThickness["minimum_size"]["min_w"]),
              max: Number(filteredThickness["maximum_size"]["max_w"]),
            })
          );
          dispatch(
            changeMinMaxLength({
              row: props.rowIndex,
              min: Number(filteredThickness["minimum_size"]["min_l"]),
              max: Number(filteredThickness["maximum_size"]["max_l"]),
            })
          );
        }
      }
    }
    if (thicknessSelect && thicknessSelect.current) {
      thicknessSelect.current.dispatchEvent(
        new Event("change", { bubbles: true })
      );
    }
  }, [thickness]);

  // Update thickness & colour when material changed
  useEffect(() => {
    let filteredMaterial =
      props.options.filter((mat) => mat.material === material).length > 0
        ? props.options.filter((mat) => mat.material === material)[0]
        : null;
    let finalColourList = [];

    if (filteredMaterial) {
      let filteredThickness = filteredMaterial.thicknesses[0];

      if (filteredThickness) {
        finalColourList = [].concat.apply(
          [],
          filteredMaterial.thicknesses.map((itm) => {
            return itm.colours;
          })
        );
      }
    }

    dispatch(
      changeColourOptions({ row: props.rowIndex, colours: finalColourList })
    );
    dispatch(changeThickness({ row: props.rowIndex, thickness: "none" }));
    dispatch(changeColour({ row: props.rowIndex, colour: "none" }));

    // Set call to order if this or any other material is true in the rows
    if (
      filteredMaterial?.call_to_order === true ||
      props.rows.filter((material) => material.callToOrder === true).length > 0
    ) {
      dispatch(changeCallToOrder({ callToOrder: true }));
    } else {
      dispatch(changeCallToOrder({ callToOrder: false }));
    }
  }, [material]);

  // Update price when colour is changed
  useEffect(() => {
    let filteredMat =
      props.options.filter((mat) => mat.material === material).length > 0
        ? props.options.filter((mat) => mat.material === material)[0]
            .thicknesses
        : null;

    if (filteredMat) {
      let filteredConfig = filteredMat.filter(
        (thick) => thick.thickness === thickness
      );

      if (filteredConfig.length > 0) {
        let filteredColour = filteredConfig[0].colours.filter(
          (pColor) => pColor.colour === color
        )[0];
        if (filteredColour) {
          const uPrice = filteredColour.price;
          let image = filteredColour.image;

          let calculatedPrice = width * length * uPrice * quantity;
          let finalPrice = isNaN(calculatedPrice)
            ? 0.0
            : Number(calculatedPrice).toFixed(2);

          dispatch(
            changePrice({
              row: props.rowIndex,
              price: Number(finalPrice).toFixed(2),
              image: image,
            })
          );
        } else {
          dispatch(
            changePrice({ row: props.rowIndex, price: Number(0).toFixed(2) })
          );
          dispatch(changeLength({ row: props.rowIndex, length: 0 }));
          dispatch(changeWidth({ row: props.rowIndex, width: 0 }));
        }
      } else {
        dispatch(
          changePrice({ row: props.rowIndex, price: Number(0).toFixed(2) })
        );
        dispatch(changeLength({ row: props.rowIndex, length: 0 }));
        dispatch(changeWidth({ row: props.rowIndex, width: 0 }));
      }
    } else {
      dispatch(changePrice({ row: props.rowIndex, price: 0.0, image: "" }));
    }
  }, [length, width, unitPrice, quantity, thickness]);

  return (
    <div className={styles.formRow}>
      <div className={parentStyles["col-large"]}>
        <div
          className={classNames(
            styles["formRow__column"],
            styles["formRow__column--select"]
          )}
        >
          <div>
            <span className={styles.formLabel}>Material</span>
            <Validation config={props.validationConfig}>
              {({ errors, fields, submitted }) => {
                return (
                  <>
                    <select
                      name={`material${props.rowIndex}`}
                      value={material}
                      onChange={(e) => {
                        dispatch(
                          changeMaterial({
                            row: props.rowIndex,
                            material: e.target.value,
                          })
                        );
                        dispatch(
                          changeColour({
                            row: props.rowIndex,
                            colour: "none",
                            image: null,
                          })
                        );
                        dispatch(
                          changeThickness({
                            row: props.rowIndex,
                            thickness: "none",
                            image: null,
                          })
                        );
                        dispatch(
                          changeRowCallToOrder({
                            row: props.rowIndex,
                            callToOrder:
                              e.target.selectedOptions[0].getAttribute(
                                "data-calltoorder"
                              ),
                          })
                        );
                      }}
                    >
                      <option key={0} value="none">
                        Please select
                      </option>
                      {props.options.map((mat, i) => (
                        <option
                          key={i + 1}
                          data-calltoorder={mat.call_to_order}
                        >
                          {mat.material}
                        </option>
                      ))}
                    </select>
                    {submitted && errors[`material${props.rowIndex}`] && (
                      <span className={styles.error}>
                        {errors[`material${props.rowIndex}`]}
                      </span>
                    )}
                  </>
                );
              }}
            </Validation>
          </div>
        </div>
        <div
          className={classNames(
            styles["formRow__column"],
            styles["formRow__column--select"]
          )}
        >
          <div>
            <span className={styles.formLabel}>Colour</span>
            <Validation config={props.validationConfig}>
              {({ errors, fields, submitted }) => {
                return (
                  <>
                    <select
                      name={`colour${props.rowIndex}`}
                      ref={colourSelect}
                      disabled={colourOptions.length > 0 ? false : true}
                      value={color !== "" ? color : "none"}
                      onChange={(e) => {
                        dispatch(
                          changeColour({
                            row: props.rowIndex,
                            colour: e.target.value,
                            image: null,
                          })
                        );
                        dispatch(
                          changeThickness({
                            row: props.rowIndex,
                            thickness: "none",
                            image: null,
                          })
                        );
                      }}
                    >
                      <option key={"-1"} value="none">
                        Please select
                      </option>
                      {[
                        ...new Map(
                          colourOptions.map((item) => [item["colour"], item])
                        ).values(),
                      ].map((col, i) => {
                        return (
                          <option key={i} value={col.colour}>
                            {col.colour}
                          </option>
                        );
                      })}
                    </select>
                    {submitted && errors[`colour${props.rowIndex}`] && (
                      <span className={styles.error}>
                        {errors[`colour${props.rowIndex}`]}
                      </span>
                    )}
                  </>
                );
              }}
            </Validation>
          </div>
        </div>
        <div
          className={classNames(
            styles["formRow__column"],
            styles["formRow__small"],
            styles["formRow__column--select"]
          )}
        >
          <div>
            <span className={styles.formLabel}>Thickness</span>
            <Validation config={props.validationConfig}>
              {({ errors, fields, submitted }) => {
                return (
                  <>
                    <select
                      name={`thickness${props.rowIndex}`}
                      ref={thicknessSelect}
                      disabled={thicknessOptions.length > 0 ? false : true}
                      value={fields.thickness}
                      onChange={(e) => {
                        dispatch(
                          changeThickness({
                            row: props.rowIndex,
                            thickness: e.target.value,
                          })
                        );
                      }}
                    >
                      <option key={"-1"} value="none">
                        Please select
                      </option>
                      {thicknessOptions.map((thick, i) => {
                        return (
                          <option key={i} value={thick.thickness}>
                            {thick.thickness}mm
                          </option>
                        );
                      })}
                    </select>
                    {submitted && errors[`thickness${props.rowIndex}`] && (
                      <span className={styles.error}>
                        {errors[`thickness${props.rowIndex}`]}
                      </span>
                    )}
                  </>
                );
              }}
            </Validation>
          </div>
        </div>
      </div>
      <div className={parentStyles["col-large"]}>
        <div
          className={classNames(
            styles["formRow__column"],
            styles["formRow__small"]
          )}
        >
          <div>
            <span className={styles.formLabel}>Width (mm)</span>
            <Validation
              key={`width${maxWidth}`}
              config={{
                [`width${props.rowIndex}`]: {
                  isRequired: "Width is required",
                  isGreaterThan: {
                    message: "Please enter a width above the minimum value",
                    value: Number(minWidth) - 1,
                  },
                  isLessThan: {
                    message: "Please enter a width below the maximum value",
                    value: Number(maxWidth) + 1,
                  },
                },
              }}
              transforms={{
                [`width${props.rowIndex}`]: (value) => Number(value),
              }}
            >
              {({ errors, fields, submitted }) => {
                return (
                  <>
                    <input
                      value={
                        fields[`width${props.rowIndex}`] === 0
                          ? ""
                          : fields[`width${props.rowIndex}`]
                      }
                      name={`width${props.rowIndex}`}
                      disabled={
                        thicknessOptions.length > 0 &&
                        colourOptions.length > 0 &&
                        color !== "none" &&
                        thickness !== "none"
                          ? false
                          : true
                      }
                      type="number"
                      required
                      placeholder="Width"
                      min={minWidth}
                      max={maxWidth}
                      onBlur={(e) => {
                        if (e.target.value) {
                          let newWidthValue = Math.min(
                            Math.max(e.target.value, minWidth),
                            maxWidth
                          );

                          dispatch(
                            changeWidth({
                              row: props.rowIndex,
                              width: Number(newWidthValue),
                            })
                          );

                          e.target.value = newWidthValue;
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value) {
                          dispatch(
                            changeWidth({
                              row: props.rowIndex,
                              width: Number(e.target.value),
                            })
                          );

                          if (
                            Number(e.target.value) > maxWidth ||
                            Number(e.target.value) < minWidth
                          ) {
                            e.target.setCustomValidity(
                              `Please enter a value between ${minWidth} and ${maxWidth}`
                            );
                            if (Number(e.target.value) > maxWidth) {
                              e.target.value = Number(maxWidth);
                            }
                          } else {
                            e.target.setCustomValidity("");
                          }

                          e.target.reportValidity();
                        }
                      }}
                    />
                    <span className={styles.formEndLabel}>mm</span>
                    {submitted && errors[`width${props.rowIndex}`] && (
                      <span className={styles.error}>
                        {errors[`width${props.rowIndex}`]}
                      </span>
                    )}
                  </>
                );
              }}
            </Validation>
          </div>
        </div>
        <div
          className={classNames(
            styles["formRow__column"],
            styles["formRow__small"]
          )}
        >
          <div>
            <span className={styles.formLabel}>Length (mm)</span>
            <Validation
              key={`length${maxLength}`}
              config={{
                [`length${props.rowIndex}`]: {
                  isRequired: "Length is required",
                  isGreaterThan: {
                    message: "Please enter a length above the minimum value",
                    value: Number(minLength) - 1,
                  },
                  isLessThan: {
                    message: "Please enter a length below the maximum value",
                    value: Number(maxLength) + 1,
                  },
                },
              }}
              transforms={{
                [`length${props.rowIndex}`]: (value) => Number(value),
              }}
            >
              {({ errors, fields, submitted }) => {
                return (
                  <>
                    <input
                      value={
                        fields[`length${props.rowIndex}`] === 0
                          ? ""
                          : fields[`length${props.rowIndex}`]
                      }
                      name={`length${props.rowIndex}`}
                      required
                      disabled={
                        thicknessOptions.length > 0 &&
                        colourOptions.length > 0 &&
                        color !== "none" &&
                        thickness !== "none"
                          ? false
                          : true
                      }
                      type="number"
                      placeholder="Length"
                      min={minLength}
                      max={maxLength}
                      onBlur={(e) => {
                        if (e.target.value) {
                          let lengthValue = Math.min(
                            Math.max(e.target.value, minLength),
                            maxLength
                          );
                          dispatch(
                            changeLength({
                              row: props.rowIndex,
                              length: Number(lengthValue),
                            })
                          );
                          e.target.value = lengthValue;
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value) {
                          dispatch(
                            changeLength({
                              row: props.rowIndex,
                              length: Number(e.target.value),
                            })
                          );

                          if (
                            Number(e.target.value) > maxLength ||
                            Number(e.target.value) < minLength
                          ) {
                            e.target.setCustomValidity(
                              `Please enter a value between ${minLength} and ${maxLength}`
                            );

                            if (Number(e.target.value) > maxLength) {
                              e.target.value = Number(maxLength);
                            }
                          } else {
                            e.target.setCustomValidity("");
                          }

                          e.target.reportValidity();
                        }
                      }}
                    />
                    <span className={styles.formEndLabel}>mm</span>
                    {submitted && errors[`length${props.rowIndex}`] && (
                      <span className={styles.error}>
                        {errors[`length${props.rowIndex}`]}
                      </span>
                    )}
                  </>
                );
              }}
            </Validation>
          </div>
        </div>
        <div
          className={classNames(
            styles["formRow__column"],
            styles["formRow__small"]
          )}
        >
          <div>
            <span className={styles.formLabel}>Quantity</span>
            <input
              required
              min="1"
              max="999"
              disabled={
                thicknessOptions.length > 0 &&
                colourOptions.length > 0 &&
                color !== "none" &&
                thickness !== "none"
                  ? false
                  : true
              }
              type="number"
              value={quantity}
              onChange={(e) => {
                dispatch(
                  changeQuantity({
                    row: props.rowIndex,
                    quantity: e.target.value,
                  })
                );
              }}
            />
          </div>
        </div>
        <div className={styles["formRow__delete_desktop"]}>
          <Button
            className={styles.deleteIcon}
            onClick={() => {
              dispatch(deleteRow({ row: props.rowIndex }));
              if (props.rowIndex === 0 && props.rows.length === 1) {
                dispatch(addRow({}));
              }
            }}
          >
            <DeleteIcon fill="#023047" />
          </Button>
        </div>
      </div>
      <div className={parentStyles["col-small"]}>
        <div
          className={classNames(
            styles["formRow__column"],
            styles["formRow__price"],
            styles["mobile_white"]
          )}
        >
          {window.innerWidth < 768 && (
            <span className={styles["mobile_price"]}>
              <PriceIcon fill="#5BCAE6" /> Product Price
            </span>
          )}{" "}
          <span>
            {!isNaN(price) && typeof price === "number"
              ? `£${Number(price).toFixed(2)}`
              : "Calculating..."}
          </span>
        </div>
      </div>
      <div className={styles["formRow__delete_mobile"]}>
          <Button
            className={styles.deleteIcon}
            onClick={() => {
              dispatch(deleteRow({ row: props.rowIndex }));
              if (props.rowIndex === 0 && props.rows.length === 1) {
                dispatch(addRow({}));
              }
            }}
          >
            <DeleteIcon fill="#ffffff" /> Remove Product
          </Button>
        </div>
    </div>
  );
};
